<div class="alert-theme">

    <mat-chip-listbox class="mat-mdc-chip-set-stacked" aria-label="Alerts">

        <mat-chip *ngFor="let alert of alertData" highlighted [color]="alert.color">
            <div class="message-div" [innerHTML]="alert?.message | sanitizeHtml">
            </div>
        </mat-chip>

    </mat-chip-listbox>

    <button class="theme-button alt-button border-base interactive-base transition-base nvt-colorbg-pb dismiss-btn"
        (click)="dismiss()" *ngIf="!hideOkayBtn">
        Ok
    </button>
</div>