<mat-card class="nvt-surface border-base">

    <mat-card-header>
        <mat-card-title>
            Publish Board
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <mat-form-field appearance="outline">
            <input matInput [formControl]="targetCustomerId"
                placeholder="Target Customer (blank defaults to configuration settings)" (keyup.enter)="save()" />
        </mat-form-field>
    </mat-card-content>

    <mat-card-actions align="end">
        <ng-container>
            <button mat-button class="dismiss-btn" color="primary" (click)="save()">
                Ok
            </button>
        </ng-container>

        <ng-container>
            <button mat-button class="dismiss-btn" color="accent" (click)="dialogRef.close(false)">
                Cancel
            </button>
        </ng-container>
    </mat-card-actions>
</mat-card>