import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { saveAs } from 'file-saver';

import { APP_ENVIRONMENT_OPTIONS, AppEnvironmentOptions, ZappsmithBaseParmDict, ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';

import { AlertService, AlertType, SessionService, ZappService } from 'src/services';
import { ConfirmationDialogService } from 'src/components/dialog-components';

@Component({
    selector: 'zapp-transfer',
    templateUrl: 'zapp-transfer.component.html',
    styleUrls: ['zapp-transfer.component.scss']
})
export class ZappTransferComponent {
    showLoadingSpinner: boolean;

    zappExportAppFilter: string;

    zappImportAppFilter: string;

    zappTransferAppFilter: string;
    zappTransferUrl: string;
    zappTransferUserName: string;
    zappTransferFacility: string;
    zappTransferPassword: string;

    comparePublishTarget: string;
    compareVersion1: string;
    compareVersion2: string;

    constructor(private confirmationDialogService: ConfirmationDialogService,
        private alertService: AlertService,
        private http: HttpClient,
        private sessionService: SessionService,
        private zappsmithWebService: ZappsmithWebService,
        private zappService: ZappService,
        @Inject(APP_ENVIRONMENT_OPTIONS) private appEnvironmentOptions: AppEnvironmentOptions) {

        this.zappTransferUserName = this.sessionService?.model?.userId;
        this.zappTransferFacility = this.sessionService?.model?.facilityId;
    }

    exportApplications(): void {
        this.showLoadingSpinner = true;
        this.http.get('/zapp_transfer', {
            params: {
                zapp_filter: this.zappExportAppFilter
            },
            responseType: 'blob'
        })
            .subscribe(blob => {
                saveAs(blob, 'zapp_transfer.json');
                this.showLoadingSpinner = false;
            });
    }

    importApplications(fileInputEvent: any) {
        if (fileInputEvent?.target?.files?.length > 0) {
            this.processImportApplicationsFile(fileInputEvent?.target?.files[0]);
        }
    }

    private processImportApplicationsFile(file: File): Promise<void> {
        const filename = file ? file.name : null;
        const params: ZappsmithBaseParmDict = {
            file,
            filename,
            zapp_filter: this.zappImportAppFilter,
        };

        this.showLoadingSpinner = true;

        return new Promise<void>((resolve, reject) => {
            this.zappsmithWebService.post('/zapp_transfer', params).then(
                result => {
                    this.alertService.addAlert({
                        title: 'Processed',
                        message: "File " + filename + " Processed",
                        type: AlertType.success,
                        require_confirm: true
                    });

                    this.showLoadingSpinner = false;
                    resolve();
                },
                result => {
                    const message = result?.status && result.data ?
                        result.status + ': ' + result.data :
                        'Failed to Process File: ' + filename;
                    this.alertService.addAlert({
                        title: 'Upload Failed',
                        message: message,
                        type: AlertType.error
                    });

                    this.showLoadingSpinner = false;
                    reject();
                }
            );
        });
    }

    importPalettes(fileInputEvent: any) {
        if (fileInputEvent?.target?.files?.length > 0) {
            this.processNEFile(fileInputEvent?.target?.files[0], 'Palettes');
        }
    }

    importNodeKinds(fileInputEvent: any) {
        if (fileInputEvent?.target?.files?.length > 0) {
            this.processNEFile(fileInputEvent?.target?.files[0], 'node_kinds');
        }
    }

    private processNEFile(file: File, project_filter: string): Promise<void> {
        const filename = file ? file.name : null;
        const params: ZappsmithBaseParmDict = {
            file,
            filename,
            project_filter: project_filter,
        };

        this.showLoadingSpinner = true;

        return new Promise<void>((resolve, reject) => {
            this.zappsmithWebService.post('/zapp_transfer', params).then(
                result => {
                    this.alertService.addAlert({
                        title: 'Processed',
                        message: "File " + filename + " Processed",
                        type: AlertType.success,
                        require_confirm: true
                    });

                    this.showLoadingSpinner = false;
                    resolve();
                },
                result => {
                    const message = result?.status && result.data ?
                        result.status + ': ' + result.data :
                        'Failed to Process File: ' + filename;
                    this.alertService.addAlert({
                        title: 'Upload Failed',
                        message: message,
                        type: AlertType.error
                    });

                    this.showLoadingSpinner = false;
                    reject();
                }
            );
        });
    }

    transferBoards(): void {
        this.zappService.transfer_server(this.zappTransferUrl, this.zappTransferUserName, this.zappTransferFacility, this.zappTransferPassword, this.zappTransferAppFilter).then(
            result => {
                this.alertService.addAlert({
                    title: 'Transferred',
                    message: "Transferred from " + this.zappTransferUrl,
                    type: AlertType.success,
                    require_confirm: true
                });
            },
            result => {
                const message = result?.message ?
                    result?.message :
                    'Transfer Failed';
                this.alertService.addAlert({
                    title: 'Error',
                    message: message,
                    type: AlertType.error
                });
            }
        )
    }

    buildZipExport(): void {
        this.showLoadingSpinner = true;
        this.http.get('/zapp_deployments_export', {
            params: {
                target: this.comparePublishTarget,
                version: this.compareVersion1
            },
            responseType: 'blob'
        })
            .subscribe(
                blob => {
                    saveAs(blob, 'boards_export.zip');
                    this.showLoadingSpinner = false;
                }
                // ,error => {
                //     console.log(error);
                //     this.alertService.addAlert({
                //         title: 'Error',
                //         message: 'Error Exporting Boards',
                //         type: AlertType.error
                //     });
                //     this.showLoadingSpinner = false;
                // }
            );
    }

    compareVersions(): void {
        const origin = this.appEnvironmentOptions.baseUrl ? this.appEnvironmentOptions.baseUrl : window.location.origin;

        const compareUrl = '/zapp_deployments_compare?' +
            'target=' + this.comparePublishTarget +
            '&version1=' + this.compareVersion1 +
            '&version2=' + this.compareVersion2;

        window.open(origin + compareUrl, '_blank');
    }

    showVersionsLog(): void {
        const origin = this.appEnvironmentOptions.baseUrl ? this.appEnvironmentOptions.baseUrl : window.location.origin;

        const logUrl = '/zapp_deployments_log?' +
            'target=' + this.comparePublishTarget;

        window.open(origin + logUrl, '_blank');
    }
}
