import { AfterViewInit, Component } from '@angular/core';
import { EditorMode, EditorOptions } from '@dicorp/html-ffe';
import { GridType, HfeDatasetService } from 'src/components/hfe-components';

import { TraceRuleService } from 'src/services';

const TRACE_RULES_FIELD = "TraceRulesGrid";

@Component({
    selector: 'zs-trace-rules',
    templateUrl: 'trace-rules.component.html',
    styleUrls: ['trace-rules.component.scss']
})
export class TraceRulesComponent implements AfterViewInit {
    editorOptions: EditorOptions;
    traceRulesLoaded: boolean;

    constructor(private hfeDatasetService: HfeDatasetService,
        private traceRulesService: TraceRuleService
    ) {
        this.setupEditorOptions();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.createDataset(COLUMNS);
            this.createRecord();
        }, 50);
    }

    clear(): void {
        this.traceRulesService.clear();
        this.traceRulesLoaded = false;
        this.createRecord();
    }

    export(): void {
        this.editorOptions?.html_ffe_api?.export_grid_data(TRACE_RULES_FIELD, 'csv', 'TraceRules.csv')
    }

    private setupEditorOptions(): void {
        this.editorOptions = new EditorOptions();
        this.editorOptions.editorMode = EditorMode.View;
    }

    private createRecord(): void {
        const datasetRecord: any = {};
        datasetRecord[TRACE_RULES_FIELD] = this.traceRulesService.ruleTraces();
        this.editorOptions?.html_ffe_api?.reload_record(datasetRecord);

        setTimeout(() => {
            this.traceRulesLoaded = true;
        });
    }

    private createDataset(columnDefs: any[]): void {
        // Add the status column
        const datasetConfig = this.hfeDatasetService.getDatasetConfigUsingColumnDefs(
            TRACE_RULES_FIELD,
            TRACE_RULES_FIELD,
            columnDefs,
            GridType.Local);

        const editorDataset: any = {
            sections: {
                "MainForm": {
                    "name": "MainForm",
                    "height": "100%",
                    "children": [
                        {
                            "dm_reference": TRACE_RULES_FIELD,
                            "type": "Grid",
                            "align_label": "Hidden",
                            "height": "100%",
                            "allow_multi_select": true,
                            "hide_grid_filter": true,
                            "show_column_filters": true,
                            // "number_of_rows": 0,
                            "children": datasetConfig.columns,
                            "skipValueMatchCheck": true
                        }
                    ]
                }
            },
            field_configurations: datasetConfig.fieldConfigurations,
            rules: datasetConfig.rules,
            menus: datasetConfig.menus,
            default_date_format: "ISO8601",
            default_time_format: "string"
        };

        this.editorOptions?.html_ffe_api?.load_dataset(editorDataset);
    }
}

const COLUMNS = [
    { heading: 'Rule', field: 'name', kind: 'text', active: true },
    { heading: 'Module', field: 'module', kind: 'text', active: true },
    { heading: 'Start', field: 'startIso', kind: 'datetime', active: true, display_format: 'MM/DD/YYYY HH:mm:ss.SSS', width: 150 },
    { heading: 'End', field: 'endIso', kind: 'datetime', active: true, display_format: 'MM/DD/YYYY HH:mm:ss.SSS', width: 150 },
    { heading: 'Duration', field: 'duration', kind: 'int', active: true, width: 75 },
    { heading: 'Bindings', field: 'bindingsRep', kind: 'text', active: true, width: 400 },
    { heading: 'Value', field: 'valueRep', kind: 'text', active: true, width: 75 },
    { heading: 'Change', field: 'changesRep', kind: 'text', active: true },
    { heading: 'Messages', field: 'messageRep', kind: 'text', active: true },
]
