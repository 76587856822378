<div class="toolbar-main-div">

    <div class="toolbar-container">
        <!-- <mat-toolbar [color]="activeCommunityProfile?.ToolbarColor ? undefined : 'primary'"
                     class="zs-toolbar"> -->
        <mat-toolbar class="zs-toolbar">

            <mat-toolbar-row class="header-toolbar-row nvt-surface border-base">
                <div class="toolbar-wrapper">

                    <!-- Left Toolbar -->
                    <div class="toolbar-menu-wrapper toolbar-left">
                        <button mat-icon-button (click)="this.toggleLeftSideNav()">
                            <mat-icon>menu</mat-icon>
                        </button>

                        <a class="toolbar-icon-wrapper" [routerLink]="''">
                            <img class="toolbar-application-icon" width="125px" [ngStyle]="applicationIconStyle"
                                [src]="clientConfiguration.logo" [alt]="clientConfiguration.portal_app_title">
                        </a>
                    </div>

                    <!-- Center Toolbar -->
                    <div class="toolbar-center">
                        <h1>
                            {{clientConfiguration?.portal_app_title ? clientConfiguration?.portal_app_title : 'Portal
                            4'}}
                        </h1>
                    </div>

                    <!-- Right Toolbar -->
                    <div class="toolbar-primary-logo-div toolbar-right">

                        <!-- Facility Select -->
                        <div class="community-name-div nvt-surface border-base border-base-color interactive-base"
                            [ngClass]="{'interactive-base': canSelectNewFacility}"
                            [matMenuTriggerFor]="canSelectNewFacility ? facilityMenu : null">
                            <div class="community-name-div-wrapper">
                                <span class="community-span active-community-span">
                                    {{userStore.activeUser?.facility?.name}}
                                </span>

                                <mat-icon *ngIf="canSelectNewFacility" class="open-community-menu-icon">
                                    expand_more
                                </mat-icon>

                            </div>
                        </div>

                        <mat-menu #facilityMenu="matMenu" [hasBackdrop]="true">
                            <div style="display: grid;">
                                <mat-list-item *ngFor="let account_facility of accountFacilitiesSorted"
                                    style="cursor: pointer;" class="interactive-base"
                                    (click)="switchFacility(account_facility.id)">

                                    <div class="community-name-div-wrapper">
                                        <span class="community-span">
                                            {{account_facility.name}}
                                        </span>
                                    </div>

                                </mat-list-item>
                            </div>
                        </mat-menu>

                        <!-- Right Side Nav Toggle -->
                        <button mat-icon-button (click)="toggleRightSideNav()">
                            <mat-icon>account_circle</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <mat-sidenav-container class="toolbar-sidenav-container" [autosize]="resizingEvent.isResizing">
        <mat-sidenav #leftMatSideNav class="left-mat-sidenav" [mode]="sidenavService.leftSideNavMode" position="start"
            [fixedInViewport]="mediaService.isMobile" [(opened)]="sidenavService.isLeftSideNavOpen">

            <!-- Pin Nav Button -->
            <div class="left-side-nav-pin-btn-div">
                <button mat-icon-button class="left-side-nav-pin-btn"
                    [ngClass]="{'rotate-90': sidenavService.isLeftSidePinned}"
                    (click)="sidenavService.toggleLeftSideNavMode()">
                    <mat-icon>push_pin</mat-icon>
                </button>
            </div>

            <!-- Left SideNav -->
            <div class="left-side-nav-mat-accordion">

                <!-- Loading Spinner -->
                <mat-card class="routes-loading" [hidden]="routesLoaded">
                    <mat-spinner mode="indeterminate" diameter="50">
                    </mat-spinner>
                </mat-card>

                <mat-accordion [hidden]="!routesLoaded">

                    <!-- Zapp App Boards -->
                    <ng-container *ngFor="let zappApp of zappAppStore.zappApps$ | async">
                        <ng-container *ngIf="!zappApp?.IsDisabled">
                            <mat-expansion-panel *ngIf="zappApp.ZappAppBoards?.length > 0">

                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ zappApp?.Label ? zappApp?.Label : zappApp?.Name }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-nav-list>
                                    <ng-container *ngFor="let zappAppBoard of zappApp.ZappAppBoards">
                                        <mat-list-item (click)="navigate([zappAppBoard.recordManagerPath])"
                                            [cdkContextMenuTriggerFor]="toolbarItemContextMenu"
                                            [cdkContextMenuTriggerData]="{ routePath: [zappAppBoard.recordManagerPath] }">
                                            {{ zappAppBoard?.PluralLabel ?
                                            zappAppBoard?.PluralLabel :
                                            zappAppBoard?.Label}}
                                        </mat-list-item>
                                    </ng-container>
                                </mat-nav-list>

                            </mat-expansion-panel>
                        </ng-container>
                    </ng-container>

                    <mat-divider></mat-divider>

                    <!-- Module Routes -->
                    <ng-container *ngFor="let moduleRoute of portalModuleRoutes">
                        <ng-container *ngIf="appRoutingService.canLoadRoute(moduleRoute)">

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ moduleRoute.title }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-nav-list>
                                    <ng-container *ngFor="let moduleChildRoute of moduleRoute.children">

                                        <ng-container *ngIf="appRoutingService.canLoadRoute(moduleChildRoute)">
                                            <mat-list-item
                                                (click)="navigate([moduleRoute.path, moduleChildRoute.path], moduleChildRoute)"
                                                [cdkContextMenuTriggerFor]="toolbarItemContextMenu"
                                                [cdkContextMenuTriggerData]="{ routePath: [moduleRoute.path, moduleChildRoute.path] }">
                                                {{ moduleChildRoute.title }}
                                            </mat-list-item>
                                        </ng-container>
                                    </ng-container>
                                </mat-nav-list>
                            </mat-expansion-panel>

                        </ng-container>
                    </ng-container>

                </mat-accordion>
            </div>

            <div class="resize-handle" (mousedown)="startResizing($event)">
            </div>
        </mat-sidenav>

        <mat-sidenav #rightMatSideNav mode="over" position="end" [fixedInViewport]="mediaService.isMobile"
            [(opened)]="isRightSideNavOpen">

            <!-- Right SideNav -->
            <mat-nav-list>

                <!-- Active User Information -->
                <mat-list-item class="active-user-info" *ngIf="userStore.activeUser?.user">
                    <mat-icon>account_circle</mat-icon>
                    <mat-label class="active-user-name">
                        <b>{{ userStore.activeUser?.user?.name }}</b>
                    </mat-label>
                    <!-- <mat-label class="active-user-email-address">
                        {{ (subscriptionStore?.activeSubscription$ | async)?.EmailAddress}}
                    </mat-label> -->
                </mat-list-item>

                <!-- Communicator Link -->
                <ng-container
                    *ngIf="canActivateContinualCommunity?.canActivate(activatedRoute.snapshot, router.routerState.snapshot)">
                    <mat-list-item (click)="openCommunicator()">
                        <div class="continual-community-link-div-wrapper">
                            <div class="continual-community-logo-container">
                                <img class="continual-community-logo-img" [src]="clientConfiguration.favicon"
                                    [alt]="clientConfiguration.logo">
                            </div>

                            <span class="continual-community-span">Open Communicator</span>
                        </div>
                    </mat-list-item>
                </ng-container>

                <!-- Trace Rules Toggle -->
                <ng-container
                    *ngIf="canActivateTraceRules?.canActivate(activatedRoute.snapshot, router.routerState.snapshot)">
                    <mat-list-item (click)="toggleTraceRules()">
                        <mat-icon>bug_report</mat-icon>
                        <mat-label>Toggle Trace Rules</mat-label>
                        <mat-icon *ngIf="!traceRuleService.traceRulesActive"
                            class="trace-rule-icon-red">toggle_off</mat-icon>
                        <mat-icon *ngIf="traceRuleService.traceRulesActive"
                            class="trace-rule-icon-green">toggle_on</mat-icon>
                    </mat-list-item>
                </ng-container>

                <!-- Support -->
                <mat-list-item (click)="navigate(['Support']); isRightSideNavOpen = false;">
                    <mat-icon>help_outline</mat-icon>
                    <mat-label>Support</mat-label>
                </mat-list-item>


                <!-- Logout -->
                <mat-list-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <mat-label>Logout</mat-label>
                </mat-list-item>

            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content>
            <div class="mat-sidenav-content-div">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <footer>{{clientConfiguration?.rawClientConfiguration?.portal_copyright}}</footer>
</div>

<ng-template #toolbarItemContextMenu let-routePath="routePath">
    <mat-card class="toolbar-context-menu" cdkMenu>
        <mat-action-list>
            <mat-list-item (click)="openNewTab(routePath)" cdkMenuItem>Open in new tab</mat-list-item>
        </mat-action-list>
    </mat-card>
</ng-template>