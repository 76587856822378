import { Component } from '@angular/core';
import { EditorMode, EditorOptions } from '@dicorp/html-ffe';
import { ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';
import { GridType, HfeDatasetService } from 'src/components/hfe-components';

import { AlertService, AlertType } from 'src/services';

const ENVIRONMENT_CONFIGURATION_FIELD = "EnvironmentConfigurationGrid";

@Component({
    selector: 'zs-environment-configuration',
    templateUrl: 'environment-configuration.component.html',
    styleUrls: ['environment-configuration.component.scss']
})
export class EnvironmentConfigurationComponent {
    editorOptions: EditorOptions;

    constructor(private hfeDatasetService: HfeDatasetService,
        private alertService: AlertService,
        private zappsmithWebService: ZappsmithWebService,) {
        this.setupEditorOptions();
    }

    export(): void {
        this.editorOptions?.html_ffe_api?.export_grid_data(ENVIRONMENT_CONFIGURATION_FIELD, 'csv', 'EnvironmentConfiguration.csv')
    }

    private setupEditorOptions(): void {
        this.editorOptions = new EditorOptions();
        this.editorOptions.editorMode = EditorMode.View;

        this.editorOptions.htmlFfeApiChanged.subscribe(api => {
            this.createDataset(COLUMNS);
            this.createRecord();
        });
    }

    private createRecord(): void {
        this.zappsmithWebService.get('/environment_configuration', {}).then(
            result => {
                const datasetRecord: any = {};
                datasetRecord[ENVIRONMENT_CONFIGURATION_FIELD] = result;
                this.editorOptions?.html_ffe_api?.reload_record(datasetRecord);
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not get records",
                    type: AlertType.error
                });
            })
    }

    private createDataset(columnDefs: any[]): void {
        // Add the status column
        const datasetConfig = this.hfeDatasetService.getDatasetConfigUsingColumnDefs(
            ENVIRONMENT_CONFIGURATION_FIELD,
            ENVIRONMENT_CONFIGURATION_FIELD,
            columnDefs,
            GridType.Local);

        const editorDataset: any = {
            sections: {
                "MainForm": {
                    "name": "MainForm",
                    "height": "100%",
                    "children": [
                        {
                            "dm_reference": ENVIRONMENT_CONFIGURATION_FIELD,
                            "type": "Grid",
                            "align_label": "Hidden",
                            "height": "100%",
                            "allow_multi_select": true,
                            "hide_grid_filter": true,
                            "show_column_filters": true,
                            "children": datasetConfig.columns
                        }
                    ]
                }
            },
            field_configurations: datasetConfig.fieldConfigurations,
            rules: datasetConfig.rules,
            menus: datasetConfig.menus,
            default_date_format: "ISO8601",
            default_time_format: "string"
        };

        this.editorOptions?.html_ffe_api?.load_dataset(editorDataset);
    }
}

const COLUMNS = [
    { heading: 'Section', field: 'section', kind: 'text', active: true },
    { heading: 'Name', field: 'name', kind: 'text', active: true },
    { heading: 'Value', field: 'value', kind: 'text', active: true },
    { heading: 'Overwritten Value', field: 'overwritten_value', kind: 'text', active: true }
]
