<mat-card>
    <mat-card-header>
        <mat-card-title>Trace Rules</mat-card-title>

        <mat-card-actions align="end">
            <button mat-button (click)="clear()" [disabled]="!traceRulesLoaded">Clear</button>
            <button mat-button (click)="export()" [disabled]="!traceRulesLoaded">Export</button>
        </mat-card-actions>
    </mat-card-header>
    <mat-card-content>
        <!-- Record Manager -->
        <zs-hfe-portal-editor [editorOptions]="editorOptions"
            [hidden]="!editorOptions?.html_ffe_api?.HtmlFFEService?.html_ffe_editor_ready">
        </zs-hfe-portal-editor>
    </mat-card-content>
</mat-card>