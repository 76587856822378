import { Injectable } from "@angular/core";

import {
    FilterOperationType, FilterOperationValue,
    ZappsmithBaseParmDict,
    ZappsmithWebService, ZappsmithWebServiceApiParams,
    ZappsmithWebServiceBoardParams,
    ZappsmithWebServiceFilterOperations, ZappsmithWebServiceParamFilter, ZappsmithWebServiceParmDict, ZappsmithWebServiceQueryParams
} from "@dicorp/zappsmith-ngx-core";

import { EntityMap, PortalFunctions } from "src/common";

import { AlertService, AlertType, FfeAlertDialogComponent, FfeAlertDialogData } from "./alerts";
import { FfeMenuService } from "./ffe-menu.service";
import { TraceRuleService } from "./trace-rule.service";
import { ICdict, RuleBinding, RuleBindingMap } from "../common/rule-binding";
import { ClientModuleRulesContext, ClientModuleRulesService } from "./client-module-rules.service";
import { CacheService } from "./cache.service";
import { ConfigurationService } from './configuration.service';
import { Dialog } from "@angular/cdk/dialog";

@Injectable({
    providedIn: 'root'
})
export class DatasetUtilsService {

    constructor(private zappsmithWebService: ZappsmithWebService,
        private cacheService: CacheService,
        private ffeMenuService: FfeMenuService,
        private alertService: AlertService,
        private traceRuleService: TraceRuleService,
        private clientModuleRulesService: ClientModuleRulesService,
        private configurationService: ConfigurationService,
        private dialog: Dialog) {
    }

    getColumnDef(name: string): Promise<any> {
        const apiParams = new ZappsmithWebServiceApiParams('column_def');
        apiParams.query.cache = true;

        const nameFilter: ZappsmithWebServiceParamFilter = {
            operation: FilterOperationValue.eq,
            type: FilterOperationType.string,
            values: [name]
        };
        ZappsmithWebServiceFilterOperations.addFilter(apiParams, 'name', nameFilter);

        return this.zappsmithWebService.getApi(apiParams)
    };

    getSettings(names: string[]): Promise<any> {
        const apiParams = new ZappsmithWebServiceApiParams('ffe_setting');
        apiParams.query.cache = true;

        const datasetsFilter: ZappsmithWebServiceParamFilter = {
            operation: FilterOperationValue.in,
            type: FilterOperationType.string,
            values: names
        };
        ZappsmithWebServiceFilterOperations.addFilter(apiParams, 'name', datasetsFilter);

        return this.zappsmithWebService.getApi(apiParams);
    };

    getCodeValue(name: string, code: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.ffeMenuService.lookup(name, code, null, null, null, true).then(
                result => {
                    const desc = result[0];
                    //var desc = !!(result[0].label) ? result[0].label : "";
                    if (desc && !!(desc.label)) {
                        desc.code = code;
                    }
                    resolve(desc);
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not get menu " + name,
                        type: AlertType.error
                    });

                    reject("Could not get menu " + name)
                });
        });
    };

    getCodeOptions(name: string, code: string, id: string): Promise<any[]> {
        return new Promise<any>((resolve, reject) => {
            this.ffeMenuService.lookup(name, code, id, true, true, false).then(
                result => {
                    const desc = result;
                    //var desc = !!(result[0].label) ? result[0].label : "";
                    resolve(desc)
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not get menu " + name,
                        type: AlertType.error
                    });
                    reject("Could not get menu " + name)
                });
        });
    };

    clearEntityCache(baseObject?: string, entityMap?: any): void {
        this.cacheService.clearCache();
    };

    getEntity(entity_name: string, entityMap: EntityMap, control_number: string): Promise<any> {
        if (!control_number) {
            return Promise.resolve(null);
        }

        return new Promise<any>((resolve, reject) => {
            let new_entity_name = entity_name;
            let id_field = 'id';
            let name_field = 'name';
            let cn_field = 'control_number';
            // var id_field = 'Id.#value';
            // var name_field = 'Name.#value';
            let type_field = 'type_link_code';
            let type_link_code_type = 'string';
            let alt_id_field = 'alternate_id';
            let facility_field = 'primary_facility_key'; // change default key from primary_facility_link
            let is_hidden_field = 'hidden';
            let is_disabled_field = 'disabled';
            let allow_cache = true;

            const params: ZappsmithBaseParmDict = { 'doc_id': control_number, cache: allow_cache };

            if (!!(entityMap) && !!(entityMap[entity_name])) {
                const mapping = entityMap[entity_name];

                if (!!(mapping['base'])) {
                    new_entity_name = mapping['base'];
                }
                if (!!(mapping['id'])) {
                    id_field = mapping['id'];
                }
                if (!!(mapping['name'])) {
                    name_field = mapping['name'];
                }
                if (!!(mapping['control_number'])) {
                    cn_field = mapping['control_number'];
                }
                if (!!(mapping['type_link_code'])) {
                    type_field = mapping['type_link_code'];
                }
                if (!!(mapping['type_link_code_type'])) {
                    type_link_code_type = mapping['type_link_code_type'];
                }
                if (!!(mapping['alternate_id'])) {
                    alt_id_field = mapping['alternate_id'];
                }
                if (!!(mapping['facility_link'])) {
                    facility_field = mapping['facility_link'];
                }
                if (!!(mapping['is_hidden'])) {
                    is_hidden_field = mapping['is_hidden'];
                }
                if (!!(mapping['is_disabled'])) {
                    is_disabled_field = mapping['is_disabled'];
                }
                if (!!(mapping['additional_parameters'])) {
                    Object.assign(params, mapping['additional_parameters']);
                }
                if (!!(mapping['allow_cache'])) {
                    allow_cache = mapping['allow_cache']?.toLowerCase() === 'true';
                }
            }

            this.zappsmithWebService.get('/' + new_entity_name, params).then(
                result => {
                    const desc = result; // not a list
                    const raw_is_hidden = PortalFunctions.deep_value(desc, is_hidden_field);
                    const is_hidden = (raw_is_hidden == "True" || raw_is_hidden == 1 || raw_is_hidden == true);
                    const raw_is_disabled = PortalFunctions.deep_value(desc, is_disabled_field);
                    const is_disabled = (raw_is_disabled == "True" || raw_is_disabled == 1 || raw_is_disabled == true);
                    const keyValue = PortalFunctions.deep_value(desc, cn_field);
                    const res = {
                        'name': PortalFunctions.deep_value(desc, name_field),
                        'id': PortalFunctions.deep_value(desc, id_field),
                        'key': keyValue === (keyValue || keyValue === "" || keyValue === 0) ? keyValue.toString() : null,
                        'type_link_code': PortalFunctions.deep_value(desc, type_field),
                        'type_link_code_type': type_link_code_type,
                        'alternate_id': PortalFunctions.deep_value(desc, alt_id_field),
                        'primary_facility_link': PortalFunctions.deep_value(desc, facility_field),
                        'is_hidden': is_hidden,
                        'is_disabled': is_disabled
                    };
                    const retval = { ...desc, ...res };

                    resolve(retval);
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not get " + entity_name,
                        type: AlertType.error
                    });
                    reject("Could not get " + entity_name);
                });
        });
    };

    getEntities(entity_name: string, entityMap: EntityMap, initialParams: any = null, hasNewReturnStructure: boolean = false): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let new_entity_name = entity_name;
            let id_field = 'id';
            let name_field = 'name';
            let cn_field = 'control_number';
            let type_field = 'type_link_code';
            let alt_id_field = 'alternate_id';
            let facility_field = 'primary_facility_key'; // was 'primary_facility_link';
            let is_hidden_field = 'hidden';
            let is_disabled_field = 'disabled';
            let type_link_code_type = 'string';
            let allow_cache = true;
            const queryParams: ZappsmithWebServiceQueryParams = initialParams ? initialParams : {};
            let hasParams = !!initialParams;
            let mapping: any = null;
            if (!!(entityMap) && !!(entityMap[entity_name])) {
                mapping = entityMap[entity_name];
                if (!!(mapping.base))
                    new_entity_name = mapping.base;
                if (!!(mapping.id)) {
                    id_field = mapping.id;
                }
                if (!!(mapping.name)) {
                    name_field = mapping.name;
                }
                if (!!(mapping.control_number)) {
                    cn_field = mapping.control_number;
                }
                if (!!(mapping.type_link_code)) {
                    type_field = mapping.type_link_code;
                }
                if (!!(mapping.type_link_code_type)) {
                    type_link_code_type = mapping.type_link_code_type;
                }
                if (!!(mapping.alternate_id)) {
                    alt_id_field = mapping.alternate_id;
                }
                if (!!(mapping.is_hidden)) {
                    is_hidden_field = mapping.is_hidden;
                }
                if (!!(mapping.is_disabled)) {
                    is_disabled_field = mapping.is_disabled;
                }
                if (!!(mapping.facility_link)) {
                    facility_field = mapping.facility_link;
                }
                if (!!(mapping.additional_parameters)) {
                    Object.assign(queryParams, mapping.additional_parameters);
                    hasParams = true;
                }
                if (!!(mapping.allow_cache)) {
                    allow_cache = mapping.allow_cache;
                }
            }
            const apiParams = new ZappsmithWebServiceApiParams(new_entity_name);
            apiParams.setQuery(queryParams);

            const cutoff = parseInt(this.configurationService.getConfigurationItem('get_via_post_cutoff', '1500'))
            const is_oversized = hasParams && apiParams && JSON.stringify(apiParams).length > cutoff;

            const resolve_entity_query_result = (result: any) => {
                const results: any[] = [];
                const totalCount = result?.count ? result.count : null;
                const desc: any[] = result?.records ? result.records : result;
                desc.forEach((value, key) => {
                    const raw_is_hidden = PortalFunctions.deep_value(value, is_hidden_field);
                    const is_hidden = (raw_is_hidden == "True" || raw_is_hidden == 1 || raw_is_hidden == true);
                    const raw_is_disabled = PortalFunctions.deep_value(value, is_disabled_field);
                    const is_disabled = (raw_is_disabled == "True" || raw_is_disabled == 1 || raw_is_disabled == true);
                    const keyValue = PortalFunctions.deep_value(value, cn_field);
                    const res = {
                        'name': PortalFunctions.deep_value(value, name_field),
                        'id': PortalFunctions.deep_value(value, id_field),
                        'key': (keyValue || keyValue === "" || keyValue === 0) ? keyValue.toString() : null,
                        'type_link_code': PortalFunctions.deep_value(value, type_field),
                        'type_link_code_type': type_link_code_type,
                        'alternate_id': PortalFunctions.deep_value(value, alt_id_field),
                        'primary_facility_link': PortalFunctions.deep_value(value, facility_field),
                        'is_hidden': is_hidden,
                        'is_disabled': is_disabled
                    };
                    results.push({ ...value, ...res });
                });
                if (mapping) {
                    mapping.lastResults = results;
                }

                const retval = hasNewReturnStructure ? { 'total_count': totalCount, 'entities': results } : results
                resolve(retval);
            }

            const reject_entity_query_result = (result: any) => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: "Could not get " + entity_name,
                    type: AlertType.error
                });
                reject("Could not get " + entity_name);
            }

            if (is_oversized && hasParams) {
                apiParams.query.force_get = true;
                apiParams.query.param_dict = JSON.stringify(apiParams.query.param_dict) as any;
                if (apiParams.query.order_by) {
                    apiParams.query.order_by = JSON.stringify(apiParams.query.order_by) as any;
                }
                this.zappsmithWebService.post('/' + apiParams.api, apiParams.query).then(
                    result => resolve_entity_query_result(result),
                    result => reject_entity_query_result(result)
                );
            } else {
                this.zappsmithWebService.getApi(apiParams).then(
                    result => resolve_entity_query_result(result),
                    result => reject_entity_query_result(result)
                );
            }
        });
    };

    getEntitiesQuery(entity_name: string, entityMap: EntityMap, params: any): Promise<any> {
        return this.getEntities(entity_name, entityMap, params, true)
    };

    getEntityDataset(entity_name: string, entityMap: EntityMap, entity: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let datasetName = entity_name;
            if (!!(entityMap) && !!(entityMap[entity_name])) {
                const mapping = entityMap[entity_name];
                datasetName = mapping['dataset_name'];
            }

            const params: ZappsmithBaseParmDict = { 'name': datasetName };

            this.zappsmithWebService.get('/dataset', { params, cache: true }).then(
                result => {
                    resolve(result);
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: 'Could not get dataset',
                        type: AlertType.error
                    });
                    reject(result);
                }
            );
        });
    };

    updateEntity(entity_name: string, entityMap: EntityMap, entity: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let new_entity_name = entity_name;
            if (!!(entityMap) && !!(entityMap[entity_name])) {
                const mapping = entityMap[entity_name];
                const topLevelPath = mapping['top_level_path'];
                if (topLevelPath) {
                    const new_entity = PortalFunctions.deep_value(entity, topLevelPath);
                    new_entity._id = entity._id;
                    new_entity._version = entity._version;
                    entity = new_entity;
                }
                if (!!(mapping['base'])) {
                    new_entity_name = mapping['base'];
                }
            }

            const params: ZappsmithBaseParmDict = { 'document': JSON.stringify(entity) };
            this.zappsmithWebService.post('/dataset', { params, cache: true }).then(
                result => {
                    resolve(result)
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: 'Could not get dataset',
                        type: AlertType.error
                    });
                    reject(result);
                });
        });

    };

    deleteEntity(entity_name: string, entityMap: EntityMap, control_number: string): Promise<any> {
        if (!control_number) {
            return Promise.resolve(true); // just give up
        }

        return new Promise<any>((resolve, reject) => {
            let new_entity_name = entity_name;

            if (!!(entityMap) && !!(entityMap[entity_name])) {
                const mapping = entityMap[entity_name];
                if (!!(mapping['base']))
                    new_entity_name = mapping['base'];
            }

            const params: ZappsmithBaseParmDict = { 'doc_id': control_number };

            this.zappsmithWebService.delete(new_entity_name, params).then(
                result => {
                    const retval = {};
                    // force the cache to be cleared
                    this.clearEntityCache();
                    resolve(retval);
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not delete " + entity_name,
                        type: AlertType.error
                    });
                    reject("Could not delete " + entity_name)
                });
        });
    };

    runRulePromise(rule: any, input_dict: any, ffe_options: any, context: ClientModuleRulesContext, currentIndexReplacement?: number[] | null): Promise<any> {
        const start = new Date();
        const rule_name = rule.name;
        const module_name = rule.kind != "RUL_Skip" ? rule.clisp_module : rule.value; // for skips we use the value as the name
        const bindings: any = {};

        for (var r in rule.bindings) {
            const v = rule.bindings[r];
            let rawValue = PortalFunctions.convertCurrentIndexes(v.value, currentIndexReplacement);

            const new_b = {
                //name: v.name,
                raw_value: rawValue,
                type: v.binding_type,
                lookup_value: (v.binding_type == "Field") ? (rawValue == "." ? input_dict : PortalFunctions.deep_value(input_dict, rawValue)) : rawValue
            };

            bindings[v.name] = new_b;
        };


        if (this.clientModuleRulesService.moduleExists(module_name)) {
            const new_bindings = new RuleBindingMap();
            let has_any = false;
            const cdict: ICdict = {
                "error": null,
                "value": true,
                "changes": {},
                "message": null,
                "paths": null,
                "rule": rule,
                "record": input_dict,
                "ffe_options": ffe_options,
                "context": context
            };

            for (var key in bindings) {
                const value = bindings[key];
                new_bindings[key] = new RuleBinding(value, cdict);
                has_any = true;
            };

            if (!has_any) {
                new_bindings["DEFAULT_EMPTY"] = new RuleBinding(null, cdict);
            }

            let result = null;
            try {
                this.clientModuleRulesService.invokeClientRule(module_name, new_bindings);
                //result = clientModuleRules[module_name](new_bindings);
            } catch (err) {
                cdict.message = "An error occurred running rule: " + rule_name;
                cdict.error = err;
                console.error(err)
            }
            // cdict set via bindings sets
            this.clientModuleRulesService.postProcessRuleResults(cdict, bindings); // raw result post processing
            this.traceRuleService.add(rule_name, module_name, start, bindings, cdict);

            return Promise.resolve(cdict);
        } else {
            return new Promise<any>((resolve, reject) => {
                const params: ZappsmithBaseParmDict = { name: rule_name, module: module_name, bindings: JSON.stringify(bindings) };

                this.zappsmithWebService.post('/module_rule/eval', params).then(
                    result => {
                        const d = result;
                        this.clientModuleRulesService.postProcessRuleResults(d, bindings); // raw result post processing
                        if (d.value == -1) {
                            console.error("Rule: " + d.message);
                            if (d.message.indexOf('Not found') == -1) {
                                this.alertService.addAlert({
                                    title: 'Error',
                                    message: "Rule: " + d.message,
                                    type: AlertType.error
                                });
                            } else {
                                d.value = 0;
                                console.error("Rule " + rule_name + " not found so returning a 0")
                            }
                        }
                        this.traceRuleService.add(rule_name, module_name, start, bindings, d);

                        resolve(d);
                    },
                    result => {
                        this.alertService.addAlert({
                            title: 'Error',
                            message: "Could not run rule " + rule.name,
                            type: AlertType.error
                        });
                        reject(null);
                    });
            });
        }
    };

    handleRuleResultAlert(result: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let message_parts: string[] = result.message.split(':');
            if (message_parts.length == 1) {
                message_parts = result.message.split('__SPLIT__');
            }

            const dialogData: FfeAlertDialogData = {
                title: message_parts.length == 1 ? 'Error' : message_parts[0],
                body: message_parts.length == 1 ? message_parts[0] : message_parts.slice(1).join(':'),
                alerts: result.alert
            }
            const dialogRef = this.dialog.open<any>(FfeAlertDialogComponent, {
                data: dialogData,
                disableClose: true
            });

            dialogRef.closed.subscribe(
                result => {
                    resolve(result);
                }
            );
        });
    }
}