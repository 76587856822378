import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'zs-publish-board-dialog',
    templateUrl: 'publish-board-dialog.component.html',
    styleUrls: ['publish-board-dialog.component.scss']
})
export class PublishBoardDialogComponent {

    targetCustomerId = new FormControl<string>(null);

    constructor(public dialogRef: MatDialogRef<PublishBoardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public title: string) {
    }

    save(): void {
        this.dialogRef.close(this.targetCustomerId.value);
    }
}
