import { Inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { AppAuthService } from "@dicorp/zappsmith-ngx-auth";
import { APP_ENVIRONMENT_OPTIONS, AppEnvironmentOptions, CommonFunctions, ZappsmithBaseParmDict, ZappsmithWebService } from "@dicorp/zappsmith-ngx-core";

import { BoardActionRule, BoardAdditionalActions, ZappAppBoard, ZappAppBoardStore } from "src/component-store";
import { AlertService, AlertType, FormDefaultService, GeneralDatasetService, SessionService, ZappService } from "src/services";

import {
    ADMIN_BOARDS_PATH, ADMIN_BOARDS_JSON,
    ZAPP_DESIGNER_PATH, ZAPP_DESIGNER_BOARDS_JSON,
    DATA_EXCHANGE_PATH, DATA_EXCHANGE_BOARDS_JSON
} from "./services";
import { ConfirmationDialogService } from "src/components/dialog-components";
import { PortalFunctions } from "src/common";
import { PublishBoardDialogComponent } from "./components";
import { MatDialog } from "@angular/material/dialog";

interface AdminBoardDict { [key: string]: ZappAppBoard; };
interface AdminBoardsDict { [key: string]: AdminBoardDict; };

@Injectable({
    providedIn: 'root'
})
export class AdminBoardsService {
    private _adminBoards: AdminBoardsDict;
    adminBoardsLoaded$ = new Subject<AdminBoardsDict>();

    constructor(private appAuthService: AppAuthService,
        private zappAppBoardStore: ZappAppBoardStore,
        private formDefaultService: FormDefaultService,
        private generalDatasetService: GeneralDatasetService,
        private sessionService: SessionService,
        private confirmationDialogService: ConfirmationDialogService,
        private zappsmithWebService: ZappsmithWebService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private zappService: ZappService,
        @Inject(APP_ENVIRONMENT_OPTIONS) private appEnvironmentOptions: AppEnvironmentOptions,) {
        this.appAuthService.activeUser$.subscribe(activeUser => {
            if (activeUser) {
                this.createAdminBoards();
            }
        });
    }

    private createAdminBoards(): void {
        this._adminBoards = {};

        // Data Exchange
        this.processAdminBoardsJson(DATA_EXCHANGE_PATH, DATA_EXCHANGE_BOARDS_JSON);

        // Zapp Designer
        this.processAdminBoardsJson(ZAPP_DESIGNER_PATH, ZAPP_DESIGNER_BOARDS_JSON);

        // Admin Boards
        this.processAdminBoardsJson(ADMIN_BOARDS_PATH, ADMIN_BOARDS_JSON);

        for (var adminBoardsKey in this._adminBoards) {
            const adminBoardsDict = this._adminBoards[adminBoardsKey];
            const adminBoards = Object.values(adminBoardsDict);
            this.zappAppBoardStore.setBoardProperties(adminBoards);
            this.zappAppBoardStore.addZappAppBoards(adminBoards);
        }

        this.adminBoardsLoaded$.next(this._adminBoards);
    }

    private processAdminBoardsJson(adminBoardPath: string, adminBoardsJson: any) {
        for (var adminBoardKey in adminBoardsJson) {
            const adminBoardJson = adminBoardsJson[adminBoardKey];
            this.processAdminBoardJson(adminBoardPath, adminBoardKey, adminBoardJson)
        }
    }

    private processAdminBoardJson(adminBoardPath: string, adminBoardKey: string, adminBoardJson: any): void {
        const adminBoard = this.createAdminBoard(adminBoardKey, adminBoardJson);

        if (adminBoard.add_board) {
            adminBoard.AddBoard = this.createAdminBoard(adminBoardPath, adminBoard.add_board);
        }

        if (adminBoardKey === 'form_default') {
            this.processFormDefaultBoard(adminBoard);
        } else if (adminBoardKey === 'zapp_board') {
            this.processZappBoardBoard(adminBoard);
        } else if (adminBoardKey === 'zapp_doc') {
            this.processZappDocBoard(adminBoard);
        } else if (adminBoardKey === 'processing_manager') {
            this.processProcessingManagerBoard(adminBoard);
        }

        if (!this._adminBoards[adminBoardPath]) {
            this._adminBoards[adminBoardPath] = {};
        }

        this._adminBoards[adminBoardPath][adminBoardKey] = adminBoard;
    }

    private createAdminBoard(adminBoardPath: string, boardJson: any): ZappAppBoard {
        const zappAppBoard = this.zappAppBoardStore.createZappAppBoard(boardJson);

        zappAppBoard.recordManagerPath = adminBoardPath + '/' + zappAppBoard.ApplicationLinkId;

        if (zappAppBoard.permissions.canView()) {
            zappAppBoard.viewRecordPath = zappAppBoard.recordManagerPath + '/View';
        }
        if (zappAppBoard.permissions.canEdit()) {
            zappAppBoard.editRecordPath = zappAppBoard.recordManagerPath + '/Edit';
        }
        if (zappAppBoard.permissions.canAdd()) {
            zappAppBoard.addRecordPath = zappAppBoard.recordManagerPath + '/Add';
        }

        zappAppBoard.detailsPath = zappAppBoard.recordManagerPath + '/Details';

        return zappAppBoard;
    }

    getAdminBoards(adminBoardsPath: string): Promise<AdminBoardDict> {
        if (this._adminBoards) {
            return Promise.resolve(this._adminBoards[adminBoardsPath]);
        } else {
            return new Promise<AdminBoardDict>(resolve => {
                const sub = this.adminBoardsLoaded$.subscribe(adminBoards => {
                    if (adminBoards) {
                        sub.unsubscribe();
                        resolve(adminBoards[adminBoardsPath]);
                    }
                })
            });
        }
    }

    private processFormDefaultBoard(zappAppBoard: ZappAppBoard): void {
        const boardAdditionalActions: BoardAdditionalActions = {
            formDefaultCopy: {
                label: 'Copy',
                refresh: true,
                action: (doc_id, selectedRows) => {
                    return this.formDefaultService.open_copy_dialog(doc_id);
                }
            },
            formDefaultReassign: {
                label: 'Reassign',
                refresh: true,
                action: (doc_id, selectedRows) => {
                    return this.formDefaultService.open_reassign_dialog(doc_id);
                }
            }
        }

        zappAppBoard.boardAdditionalActions = boardAdditionalActions;
    }

    private processZappBoardBoard(zappAppBoard: ZappAppBoard): void {
        if (!zappAppBoard.actionRules) {
            zappAppBoard.actionRules = [];
        }

        const buildBoardActionRule: BoardActionRule = {
            label: 'Build Board',
            confirmationMessage: "Are you sure you want to build the board?",
            isEmptyRowsAllowed: false,
            refreshChildren: true,
            standAlone: true,
            actionOverride: (currentKeyValues: string[]) => {
                if (currentKeyValues?.length > 0) {
                    return this.generalDatasetService.buildBoard(currentKeyValues[0]);
                } else {
                    return Promise.reject();
                }
            }
        }

        zappAppBoard.actionRules.push(buildBoardActionRule);

        const publishBoardActionRule: BoardActionRule = {
            label: 'Publish Boards',
            confirmationMessage: "Are you sure you want to publish the selected boards?",
            isEmptyRowsAllowed: false,
            refreshChildren: true,
            actionOverride: (currentKeyValues: string[], selectedRows?: any[]) => {
                const boardIds = currentKeyValues;
                const appIds = selectedRows?.map(selectedRow => {
                    return PortalFunctions.deep_value(selectedRow, "Board.ApplicationLink.#value");
                });

                return new Promise<boolean>((resolve, reject) => {
                    const dialogRef = this.dialog.open(PublishBoardDialogComponent, {
                        disableClose: true,
                        closeOnNavigation: true,
                        hasBackdrop: true,
                        data: "Form Default Copy"
                    });

                    dialogRef.afterClosed().subscribe((targetResult: boolean | string) => {
                        if (targetResult !== true && targetResult !== false) {
                            this.zappService.publish(boardIds, appIds, targetResult).then(
                                result => {
                                    this.alertService.addAlert({
                                        title: 'Publish Submitted',
                                        message: "Board Publish Requested with " + result.status + 'to ' + targetResult,
                                        type: AlertType.success,
                                        require_confirm: true
                                    });
                                    resolve(true);
                                },
                                result => {
                                    this.alertService.addAlert({
                                        title: 'Error',
                                        message: "Could not publish board " + result?.message,
                                        type: AlertType.error
                                    });
                                    resolve(false);
                                }
                            )
                        } else {
                            resolve(false);
                        }
                    });
                })
            }
        }

        zappAppBoard.actionRules.push(publishBoardActionRule);
    }

    private processZappDocBoard(zappAppBoard: ZappAppBoard): void {

        const getZappDoc = (key: string): Promise<any> => {
            return new Promise<any>((resolve, reject) => {
                this.generalDatasetService.get(zappAppBoard?.ffeEditorOptions?.baseObject, key).then(
                    result => {
                        resolve(result)
                    },
                    result => {
                        resolve(null)
                    });
            });
        }

        const openContent = (entity: any, fieldPath: string): void => {
            if (entity?.Document) {
                const origin = this.appEnvironmentOptions.baseUrl ? this.appEnvironmentOptions.baseUrl : window.location.origin;
                const path = CommonFunctions.getMdlObjectValue(entity.Document[fieldPath]);
                window.open(origin + path, '_blank');
            }
        }

        zappAppBoard.doubleClickActionOverride = (entity: any) => {
            openContent(entity, 'ExternalEditUrl');
            return Promise.resolve();
        };

        const boardAdditionalActions: BoardAdditionalActions = {
            viewContent: {
                label: 'View Doc',
                action: (doc_id, selectedRows) => {
                    if (selectedRows?.length > 0) {
                        const selectedRow = selectedRows[selectedRows.length - 1];
                        openContent(selectedRow, 'ExternalViewUrl');
                    }
                    // getZappDoc(doc_id).then(result => {
                    //     openContent(result, 'ExternalViewUrl');
                    // })
                    return Promise.resolve(true);
                }
            },
            editContent: {
                label: 'Edit Doc',
                action: (doc_id, selectedRows) => {
                    if (selectedRows?.length > 0) {
                        const selectedRow = selectedRows[selectedRows.length - 1];
                        openContent(selectedRow, 'ExternalEditUrl');
                    }
                    // getZappDoc(doc_id).then(result => {
                    //     openContent(result, 'ExternalEditUrl');
                    // })
                    return Promise.resolve(true);
                }
            }
        }

        zappAppBoard.boardAdditionalActions = boardAdditionalActions;
    }

    private processProcessingManagerBoard(zappAppBoard: ZappAppBoard): void {
        // Additional Actions
        const openContent = (path: string): void => {
            const origin = this.appEnvironmentOptions.baseUrl ? this.appEnvironmentOptions.baseUrl : window.location.origin;
            window.open(origin + path, '_blank');
        }

        const boardAdditionalActions: BoardAdditionalActions = {
            downloadReport: {
                label: 'Report',
                action: (doc_id, selectedRows) => {
                    const path = '/submission/download_report?submission_key=' + doc_id;
                    openContent(path);
                    return Promise.resolve(false);
                }
            }
        }

        if (this.sessionService.hasPermission('SubmissionAdminAbility')) {
            boardAdditionalActions['downloadContent'] = {
                label: 'Get Sub',
                action: (doc_id, selectedRows) => {
                    const path = '/submission/download_content?submission_key=' + doc_id;
                    openContent(path);
                    return Promise.resolve(false);
                }
            }

            boardAdditionalActions['resubmit'] = {
                label: 'Resubmit',
                refresh: true,
                action: (doc_id, selectedRows) => {
                    return new Promise<boolean>((resolve, reject) => {
                        this.confirmationDialogService.openConfirmationDialog({
                            title: 'Rerun Submission',
                            message: 'Are you sure you want to rerun this submission?'
                        }).then(
                            result => {
                                if (result) {
                                    const path = '/submission/resubmit?submission_key=' + doc_id;
                                    this.zappsmithWebService.post(path, {});
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            },
                            result => {
                                resolve(false);
                            }
                        );
                    });
                }
            }
        }

        zappAppBoard.boardAdditionalActions = boardAdditionalActions;

        // Board Action Rules
        zappAppBoard.actionsLabel = 'Upload';
        if (!zappAppBoard.actionRules) {
            zappAppBoard.actionRules = [];
        }

        const uploadSubmissionFile = (processing_mode: string = 'Submission'): Promise<boolean> => {
            return new Promise<boolean>((resolve, reject) => {
                var input = document.createElement('input');
                input.type = 'file';

                input.onchange = (e) => {
                    var file = (e.target as any).files[0];
                    this.processSubmissionFile(file, processing_mode).then(
                        result => { resolve(result); },
                        result => { resolve(result); }
                    );
                }

                input.onclose = (e) => {
                    resolve(false);
                }

                input.click();
            });
        }

        // Submission File Upload
        zappAppBoard.actionRules.push({
            label: 'Submission File Upload',
            isEmptyRowsAllowed: true,
            refreshChildren: true,
            actionOverride: (currentKeyValues: string[]) => {
                return uploadSubmissionFile('Submission');
            }
        });

        // Verification File Upload
        if (this.sessionService.hasPermission('SubmissionImpactAnalysisAbility')) {
            zappAppBoard.actionRules.push({
                label: 'Verification File Upload',
                isEmptyRowsAllowed: true,
                refreshChildren: true,
                actionOverride: (currentKeyValues: string[]) => {
                    return uploadSubmissionFile('VerificationOnly');
                }
            });
        }

        // Validation File Upload
        if (this.sessionService.hasPermission('SubmissionValidationOnlyAbility')) {
            zappAppBoard.actionRules.push({
                label: 'Validation File Upload',
                isEmptyRowsAllowed: true,
                refreshChildren: true,
                actionOverride: (currentKeyValues: string[]) => {
                    return uploadSubmissionFile('ValidationOnly');
                }
            });
        }

        // Row Styling
        zappAppBoard.diGetRowStyle = (field_name: string, row: any) => {
            if (!row) {
                return null;
            }

            const status = row?.status;
            switch (status) {
                case 'Success': {
                    return null;
                };
                case 'Processing': {
                    return { 'background-color': '#90EE90 !important' }
                };
                case 'Pending': {
                    return { 'background-color': '#FFFFCC !important' }
                };
                case 'SystemError':
                case 'Error':
                case 'Invalid': {
                    return { 'background-color': '#FF9966 !important' };
                };
                default: {
                    return null;
                }
            }
        };
    }

    private processSubmissionFile(file: File, processing_mode: string = "Submission"): Promise<boolean> {
        const filename = file ? file.name : null;
        const params: ZappsmithBaseParmDict = {
            filename,
            file,
            'processing_mode': processing_mode
        };

        return new Promise<boolean>((resolve, reject) => {
            this.zappsmithWebService.post('/submission', params).then(
                result => {
                    this.alertService.addAlert({
                        title: 'Uploaded',
                        message: "File " + filename + " Uploaded",
                        type: AlertType.success
                    });
                    resolve(true);
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Upload Failed',
                        message: "File " + filename + " Failed to Upload",
                        type: AlertType.error
                    });
                    resolve(false);
                }
            );
        });

        // if (file && !file.$error) {
        //     file.upload = Upload.upload({
        //         url: '/submission',
        //         file: file,
        //         fields: parms,
        //         sendFieldsAs: 'form'
        //     });

        //     file.upload.then(function (response) {
        //         $timeout(function () {
        //             file.result = response.data;
        //         });
        //     }, function (response) {
        //         if (response.status > 0)
        //             $scope.errorMsg = response.status + ': ' + response.data;
        //     });

        //     file.upload.progress(function (evt) {
        //         file.progress = Math.min(100, 100.0 *
        //             evt.loaded / evt.total);
        //     })
        //         .success(function (data, status, headers, config) {
        //             $log.info('file ' + config.file.name + ' uploaded. Response: ' + data);
        //             this.alertService.addAlert({
        //                 title: 'Uploaded',
        //                 message: "File " + config.file.name + " Uploaded",
        //                 type: 'errorAlert', // this has to match the alert-type attribute
        //                 alertClass: 'alert-info' //the alert element will have this class, good for css styling
        //             });
        //             //$scope.model.type_link = null; //clear it
        //             //$scope.reset_type_link();
        //             $scope.f = null;
        //             $scope.refreshSubmissions();
        //         })
        //         .error(function (data, status, headers, config) {
        //             $log.info('error status: ' + status);
        //         });
        // }
    };
}