import { Component } from '@angular/core';
import { EditorMode, EditorOptions } from '@dicorp/html-ffe';
import { GridType, HfeDatasetService } from 'src/components/hfe-components';

import { AlertService, AlertType, ClientModuleRulesService, ConfigurationService } from 'src/services';

const MODULE_RULES_FIELD = "ModuleRulesGrid";

@Component({
    selector: 'zs-module-rules',
    templateUrl: 'module-rules.component.html',
    styleUrls: ['module-rules.component.scss']
})
export class ModuleRulesComponent {
    editorOptions: EditorOptions;

    constructor(private hfeDatasetService: HfeDatasetService,
        private configurationService: ConfigurationService) {

        const clientModuleRules = this.configurationService.getModuleRules();
        if (clientModuleRules && clientModuleRules?.length > 0) {
            this.setupEditorOptions();
        } else {
            const _clientModuleRulesBuiltSub = this.configurationService.moduleRulesBuilt$.subscribe(
                loaded => {
                    if (loaded) {
                        _clientModuleRulesBuiltSub.unsubscribe();
                        this.setupEditorOptions();
                    }
                });
        }
    }

    export(): void {
        this.editorOptions?.html_ffe_api?.export_grid_data(MODULE_RULES_FIELD, 'csv', 'ModuleRules.csv')
    }

    private setupEditorOptions(): void {
        this.editorOptions = new EditorOptions();
        this.editorOptions.editorMode = EditorMode.View;

        this.editorOptions.htmlFfeApiChanged.subscribe(api => {
            this.createDataset(COLUMNS);
            this.createRecord();
        });
    }

    private createRecord(): void {
        const datasetRecord: any = {};
        datasetRecord[MODULE_RULES_FIELD] = this.configurationService.getModuleRules();
        this.editorOptions?.html_ffe_api?.reload_record(datasetRecord);
    }

    private createDataset(columnDefs: any[]): void {
        // Add the status column
        const datasetConfig = this.hfeDatasetService.getDatasetConfigUsingColumnDefs(
            MODULE_RULES_FIELD,
            MODULE_RULES_FIELD,
            columnDefs,
            GridType.Local);

        const editorDataset: any = {
            sections: {
                "MainForm": {
                    "name": "MainForm",
                    "height": "100%",
                    "children": [
                        {
                            "dm_reference": MODULE_RULES_FIELD,
                            "type": "Grid",
                            "align_label": "Hidden",
                            "height": "100%",
                            "allow_multi_select": true,
                            "hide_grid_filter": true,
                            "show_column_filters": true,
                            // "max_row_height": '100px',
                            "children": datasetConfig.columns
                        }
                    ]
                }
            },
            field_configurations: datasetConfig.fieldConfigurations,
            rules: datasetConfig.rules,
            menus: datasetConfig.menus,
            default_date_format: "ISO8601",
            default_time_format: "string"
        };

        this.editorOptions?.html_ffe_api?.load_dataset(editorDataset);
    }
}

const COLUMNS = [
    { heading: 'Module', field: 'name', kind: 'text', active: true },
    { heading: 'Definition', field: 'definition', kind: 'text', active: true },
    { heading: 'Bindings', field: 'bindings', kind: 'text', active: true, use_rich_text: true },
    { heading: 'Details', field: 'details', kind: 'text', active: true },
    { heading: 'Location', field: 'location', kind: 'text', active: true },
]
